
import { mapGetters } from 'vuex'

export default {
  middleware: 'auth',
  components: {
    CoreDrawer: () => import('@/components/core/Drawer'),
    CoreFooter: () => import('@/components/core/Footer'),
    CoreAppBar: () => import('@/components/core/AppBar'),
    CoreView: () => import('@/components/core/View'),
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    source: String,
  },
  data: () => ({
    drawer: null,
  }),
  computed: {
    ...mapGetters(['loggedInUser']),
  },
  mounted() {
    this.checkRole()
  },
  methods: {
    checkRole() {
      if (
        this.loggedInUser?.role_id !== 'f7dad830-8bf6-11ed-baaf-c39f0262afa1' &&
        this.loggedInUser?.is_staff === false
      ) {
        this.$router.push('/').catch(() => {})
      }
    },
  },
  head: {
    bodyAttrs: {
      class: '',
    },
  },
}
